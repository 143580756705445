const {BASE_URL} = require('./client');

const ORG_FETCH = BASE_URL + '/api/org/fetch';
const ORG_FETCH_PARTICULAR = BASE_URL + '/api/org/fetch/';
const ORG_UPDATERATING = BASE_URL + '/api/org/bookings/';
const ORG_FETCH_PARTNERS = BASE_URL + '/api/partner/get';
const ORG_FETCH_PARTICULAR_REVIEW = BASE_URL + '/api/org/fetch/review/';
module.exports = {
    ORG_FETCH,
    ORG_FETCH_PARTICULAR,
    ORG_UPDATERATING,
    ORG_FETCH_PARTNERS,
    ORG_FETCH_PARTICULAR_REVIEW
}