import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Container } from '@mui/material';
import axios from 'axios';
import { ORG_FETCH_PARTNERS } from '../../api/org'; // Make sure this endpoint returns all partners

const PartnersPage = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all partners
  useEffect(() => {
    axios.get(ORG_FETCH_PARTNERS)
      .then((response) => {
        setPartners(response.data); // Assuming the API response structure is { data: [{...}, {...}] }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching partners:', error);
        setLoading(false);
      });
  }, []);

  // Define columns for DataGrid
  const columns = [
    { field: 'orgName', headerName: 'Organization Name', width: 200 },
    { field: 'repName', headerName: 'Representative Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'designation', headerName: 'Designation', width: 150 },
    { field: 'whatsapp', headerName: 'WhatsApp Number', width: 150 },
    { field: 'purpose', headerName: 'Purpose', width: 200 },
    {
      field: 'proposal',
      headerName: 'Proposal',
      width: 200,
      renderCell: (params) => {
        const proposalUrl = params.row.proposal; // Assuming this is the URL to the PDF
        return proposalUrl ? (
          <a href={proposalUrl} target="_blank" rel="noopener noreferrer">
            <button style={{ padding: '5px 10px', backgroundColor: '#3f51b5', color: 'white', border: 'none', borderRadius: '4px' }}>
              View Proposal
            </button>
          </a>
        ) : (
          <span>Not Provided</span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Joined Date',
      width: 150,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleDateString(),
    },
  ];

  return (
    <Container sx={{ width: '100%' }}>
      <Box sx={{ mt: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Partners List
        </Typography>
        <Box sx={{ height: '90vh', width: '100%' }}>
          <DataGrid
            rows={partners}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row._id} // Use _id as the unique identifier for each row
            loading={loading}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default PartnersPage;
