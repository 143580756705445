import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container } from '@mui/material';
import axios from 'axios';
import { ORG_FETCH } from '../../api/org';

const OrgList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = () => {
      axios.get(ORG_FETCH)
        .then((response) => {
          // Ensure each user has a unique id
          const usersWithIds = response.data.data.map((user, index) => ({
            ...user,
            id: user._id || index,  // Use _id if available, otherwise index as fallback
          }));
          setUsers(usersWithIds);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching users:', error);
          setLoading(false);
        });
    };
  
    fetchUsers();
  }, []);
  

  // Define columns for DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'role', headerName: 'Role', width: 150 },
    { field: 'orgCode', headerName: 'ORG ID', width: 150 },
    { field: 'totalAppearingStudents', headerName: 'Enrolled Students', width: 150 },

  ];

  // Handle row click
  const handleRowClick = (params) => {
    navigate(`/org/${params.row._id}`);
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Organization List
        </Typography>

        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            loading={loading}
            onRowClick={handleRowClick}
            componentsProps={{
      row: {
        style: { cursor: 'pointer' }  // Add cursor pointer style to rows
      }
    }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default OrgList;
