import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Buttons from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import "./style.css";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Home = ({
  isAuth,
a_watchnow,
a_events,
a_announce,
a_websiteAccess,
a_education,
a_email,
a_startupshort,
a_adminboard,
a_blog,
a_mobilenumbercleanup,
a_user,
a_learner,
a_mainpage,
a_kitpage,
a_funding,
a_virtualcampus,
a_memberships,
a_courses,
a_updatelink,
a_casestudy,
a_badge,
a_applycourses,
a_eventcertificate,
a_dailygoals,
a_createcourse,
a_analytics,
a_events_register,
a_events1,
a_lab,
a_workex,
a_editcourse,
a_generatelink,
a_redeem,
a_certificates,
a_admincertificates,
a_brochuredetails,
a_workexperience,
a_workexperienceapproval,
a_payments,
a_mentors,
a_hire,
a_scholarship,
a_availability,
a_queries,
a_booking,
a_org
}) => {
  const [openCreate, setOpenCreate] = useState(false);

  // Create PopUp
  const createBox = (
    <div className="popup" id="popup-1">
      <div className="overlay">
        <div className="content">
          <div className="close-btn" onClick={() => setOpenCreate(false)}>
            <CloseOutlinedIcon />
          </div>
          <div className="btn_box">
            {a_email && (
              <Link to={`/emails`} style={{ textDecoration: "none" }}>
                <Buttons
                  variant="contained"
                  onClick={() => setOpenCreate(false)}
                  className="email_btn1"
                  // style={{ marginRight: "50px" }}
                >
                  Promotional Emails
                </Buttons>
              </Link>
            )}

            <Link to={`/email`} style={{ textDecoration: "none" }}>
              <Buttons
                variant="contained"
                onClick={() => setOpenCreate(false)}
                className="email_btn2"
              >
                Welcome Emails
              </Buttons>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {isAuth ? (
        <Box>
          {a_watchnow && (
            <Link to="/watchnow" style={{ textDecoration: "none" }}>
              <Button>Watch Now</Button>
            </Link>
          )}
          {a_events && (
            <Link to="/bookevents" style={{ textDecoration: "none" }}>
              <Button>Book Events</Button>
            </Link>
          )}
          {a_announce && (
            <Link to="/announcement" style={{ textDecoration: "none" }}>
              <Button>Announcements</Button>
            </Link>
          )}
          {a_education && (
            <Link to="/learn" style={{ textDecoration: "none" }}>
              <Button>Learn Page</Button>
            </Link>
          )}
          {a_email && (
            <Link style={{ textDecoration: "none" }}>
              <Button onClick={() => setOpenCreate(true)}>Email</Button>
            </Link>
          )}
          {a_websiteAccess &&(
            <Link to="/access" style={{ textDecoration: "none" }}>
              <Button>Website access</Button>
            </Link>
          )}
          {/* <Link to="/notifications" style={{"textDecoration":'none'}}><Button>Notifications</Button></Link> */}
          {a_memberships&&<Link to="/memberships" style={{ textDecoration: "none" }}>
            <Button>MemberShips</Button>
          </Link>}
          {a_updatelink&&<Link to="/updateLinks" style={{ textDecoration: "none" }}>
            <Button>Update Links</Button>
          </Link>}
          {a_courses&&<Link to="/courses" style={{ textDecoration: "none" }}>
            <Button>Courses</Button>
          </Link>}
          {a_funding&&<Link to="/funding" style={{ textDecoration: "none" }}>
            <Button>Funding</Button>
          </Link>}
          {a_virtualcampus&&<Link to="/virtualCampus" style={{ textDecoration: "none" }}>
            <Button>Virtual Campus</Button>
          </Link>}
          {a_blog&&<Link to="/blog" style={{ textDecoration: "none" }}>
            <Button>BLog</Button>
          </Link>}
          {a_mainpage&&<Link to="/mainPage" style={{ textDecoration: "none" }}>
            <Button>MainPage</Button>
          </Link>}
          {a_kitpage&&<Link to="/KitPage" style={{ textDecoration: "none" }}>
            <Button>KitPage</Button>
          </Link>}
          {a_learner&&<Link to="/Learner_landing" style={{ textDecoration: "none" }}>
            <Button>Learner_Landing_Page</Button>
          </Link>}
          {a_user&&<Link to="/user" style={{ textDecoration: "none" }}>
            <Button>user</Button>
          </Link>}
          {a_startupshort&&<Link to="/startupshortsadmin" style={{ textDecoration: "none" }}>
            <Button>StartUp Shorts Admin</Button>
          </Link>}
          {a_adminboard&&<Link to="/adminboard" style={{ textDecoration: "none" }}>
            <Button>Admin Board</Button>
          </Link>}
          {a_mobilenumbercleanup&&<Link to="/mobilenumbercleanup" style={{ textDecoration: "none" }}>
            <Button>Mobile Numbers Cleanup</Button>
          </Link>}
          {a_casestudy && <Link to="/casestudy" style={{ textDecoration: "none" }}>
            <Button>Case Study</Button>
          </Link>}
          {a_badge && <Link to="/badge" style={{ textDecoration: "none" }}>
            <Button>Badges</Button>
          </Link>}
          {a_badge && <Link to="/partnerwithus" style={{ textDecoration: "none" }}>
            <Button>Partner With Us</Button>
          </Link>}
          {a_badge && <Link to="/contact" style={{ textDecoration: "none" }}>
            <Button>Contact</Button>
          </Link>}
          {a_applycourses && <Link to="/applycourse" style={{ textDecoration: "none" }}>
            <Button>Apply Course Leads</Button>
          </Link>}
          {a_redeem && <Link to="/redeem" style={{ textDecoration: "none" }}>
            <Button>Redeem</Button>
          </Link>}
          {a_dailygoals && <Link to="/dailygoals" style={{ textDecoration: "none" }}>
            <Button>Daily Goal</Button>
          </Link>}
          {a_createcourse && <Link to="/CreateCoursePage" style={{ textDecoration: "none" }}>
            <Button>Create Course</Button>
          </Link>}
          {a_editcourse && <Link to="/aboutcources" style={{ textDecoration: "none" }}>
            <Button>Edit Cources</Button>
          </Link>}
          {a_events1 && <Link to="/events" style={{ textDecoration: "none" }}>
            <Button>Events</Button>
          </Link>}
          {a_events_register &&<Link to="/registeredEvents" style={{ textDecoration: "none" }}>
            <Button>Event Registrations</Button>
          </Link>}
          
          {a_eventcertificate && <Link to="/events/certificates" style={{ textDecoration: "none" }}>
            <Button>Event Certificate</Button>
          </Link>}
          {a_lab &&<Link to="/lab" style={{ textDecoration: "none" }}>
            <Button>Lab</Button>
          </Link>}
          {a_analytics && <Link to="/analytics" style={{ textDecoration: "none" }}>
            <Button>Analytics</Button>
          </Link>}
          {a_generatelink && <Link to="/checkoutlink" style={{ textDecoration: "none" }}>
            <Button>Generate Checkout Link</Button>
          </Link>}
          {a_certificates && <Link to="/certificates" style={{ textDecoration: "none" }}>
            <Button>Certificates</Button>
          </Link>}
          {a_admincertificates && <Link to="/admin/certificates" style={{ textDecoration: "none" }}>
            <Button>Admin Certificate</Button>
          </Link>}
          {a_brochuredetails && <Link to="/brochuredetails" style={{ textDecoration: "none" }}>
            <Button>Brochure Details</Button>
          </Link>}
          {a_workexperience && <Link to="/workex" style={{ textDecoration: "none" }}>
            <Button>Work Ex</Button>
          </Link>}
          {a_workexperienceapproval && <Link to="/workex/approval" style={{ textDecoration: "none" }}>
            <Button>Work Ex Approval</Button>
          </Link>}
          {a_payments && <Link to="/payments" style={{ textDecoration: "none" }}>
            <Button>Payments Status</Button>
          </Link>}
          {a_mentors && <Link to="/mentors" style={{ textDecoration: "none" }}>
            <Button>Mentor Applications</Button>
          </Link>}
          {a_hire && <Link to="/hires" style={{ textDecoration: "none" }}>
            <Button>Hiring Entries</Button>
          </Link>}
          {a_scholarship && <Link to="/scholarship" style={{ textDecoration: "none" }}>
            <Button>Scholarship Applications</Button>
          </Link>}
          {a_queries && <Link to="/queries" style={{ textDecoration: "none" }}>
            <Button>Queries</Button>
          </Link>}
          {a_booking && <Link to="/bookings" style={{ textDecoration: "none" }}>
            <Button>Booking</Button>
          </Link>}
          {a_availability && <Link to="/availability" style={{ textDecoration: "none" }}>
            <Button>Availability For Interview</Button>
          </Link>}
          {a_org && <Link to="/org" style={{ textDecoration: "none" }}>
            <Button>Organization Login</Button>
          </Link>}
          {true && <Link to="/orglist" style={{ textDecoration: "none" }}>
            <Button>Review Org</Button>
          </Link>}
          {true && <Link to="/partner" style={{ textDecoration: "none" }}>
            <Button>Partner List</Button>
          </Link>}
        </Box>
      ) : (
        <Box>
          <Typography>Please login to continue</Typography>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Button>Login</Button>
          </Link>
        </Box>
      )}
      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        {createBox}
      </Modal>

    </div>
  );
};

export default Home;
